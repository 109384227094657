/* eslint-disable max-len */

export const colors = {
  // primary
  black: "#111111",
  orange: "#BA4637",
  white: "#fff",
  slate: "#676A72",
  gold: "#FCFDCC",
  brownDark: "#332321",
  brownLite: "#564745",
  teal: "#20C997",
  tealLite: "#00FFB4",

  // secondary
  pink: "#C96D72",
  peach: "#F09F89",
  purple: "#9E4F6C",
  pinkLite: "#FED1C5",
  green: "#007C58",
  darkGreen: "#05241B",

  // neutral
  smoke: "#30353F",
  silver: "#D4D4D4",
  cream: "#F1EDE5",
};

export const breakpoints = {
  mobileS: "320px",
  mobile: "767px",
  tablet: "1200px",
  laptop: "1440px",
  desktop: "2560px",
};

export const eases = {
  inOut: "cubic-bezier(0.75, 0, 0.25, 1)",
  out: "cubic-bezier(0.0, 0.75, 0.25, 1)",
  in: "cubic-bezier(0.75, 0.0, 1, 0.25)",
};

export const zIndices = {
  hideBehind: -1,
  lightningClip: 1,
  championImg: 2,
  videoOverlay: 3,
};

export const spacing = {
  base: "16px",
  sectionPaddingMobile: "64px",
  sectionPaddingTablet: "88px",
  sectionPaddingLaptop: "128px",
};
